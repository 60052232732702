<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h3>Professores</h3>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <b-alert
        v-if="hadFirstRun && !mentors.items.length"
        show
        variant="warning"
      >
        Nenhum professor encontrado
        {{
          $route.query.questionId
            ? "capaz de lhe auxiliar com esta questão"
            : ""
        }}
      </b-alert>

      <div v-if="loading" class="d-flex justify-content-center mb-3">
        <b-spinner label="Carregando"></b-spinner>
      </div>

      <div
        v-for="mentor in mentors.items"
        :key="mentor.id"
        class="col-xl-4 col-md-6"
      >
        <div class="card directory-card">
          <div class="card-body">
            <div class="media">
              <img
                :src="
                  mentor.avatar
                    ? $getImageUrl(mentor.avatar.originalName)
                    : $defaultUserAvatar
                "
                :alt="mentor.username"
                class="img-fluid img-thumbnail rounded-circle avatar-lg"
              />
              <div class="media-body ml-3">
                <router-link
                  :to="{
                    name: 'profile',
                    params: {
                      username: mentor.username,
                    },
                  }"
                  target="_blank"
                >
                  <h5 class="text-primary font-size-18 mt-0 mb-1">
                    {{ mentor.name }} {{ mentor.surname }}
                  </h5>
                  <p class="font-size-12 mb-2">@{{ mentor.username }}</p>
                </router-link>
                <template v-if="mentor.email">
                  <p class="mb-0">{{ mentor.email }}</p>
                </template>
              </div>
            </div>
            <hr />
            <template v-if="mentor.lastOnline != null">
              <p class="mb-0">
                <strong>Última vez online:</strong>
                {{ mentor.lastOnline | moment("from") }}
              </p>
            </template>
            <template v-if="mentor.averageMinutesToAnswer">
              <p class="mb-0">
                <strong>Responde:</strong>
                {{
                  $options
                    .moment()
                    .to(
                      $options
                        .moment()
                        .add(mentor.averageMinutesToAnswer, "minutes")
                    )
                }}
              </p>
            </template>
            <template v-if="mentor.percClosedConversations != null">
              <p class="mb-0">
                <strong>Taxa de soluções:</strong>
                {{ mentor.percClosedConversations }}%
              </p>
            </template>
            <b-button
              @click.prevent="chooseMentor(mentor)"
              pill
              variant="info"
              class="mx-auto d-block"
            >
              <i class="mdi mdi-plus"></i> Escolher
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import * as api from "@/api";
import moment from "moment";

export default {
  moment,

  page: {
    title: "Professores",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
  },

  data() {
    return {
      loading: false,
      hadFirstRun: false,
      mentors: [],
    };
  },

  created() {
    this.getAllMentors();
  },

  methods: {
    getAllMentors() {
      this.loading = true;

      api
        .findMentors({
          questionId: this.$route.query.questionId,
        })
        .then((mentors) => {
          this.mentors = mentors;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar professores!", "error");
        })
        .finally(() => {
          this.loading = false;
          this.hadFirstRun = true;
        });
    },

    chooseMentor(mentor) {
      api
        .selectMentor(mentor.id, this.$route.query.questionId)
        .then(({ conversation }) => {
          this.$router.push({
            name: "viewConversation",
            params: {
              id: conversation.id,
            },
          });
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao selecionar professor!", "error");
        });
    },
  },
};
</script>
